import React from 'react';
import styled from 'styled-components';
import {Descriptions, Switch, Button, message, Alert} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import AdminLineDivider from '../../Components/AdminLineDivider';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';

export default function AdminUserCheckoutPage(props) {
  const [formSpec, setFormSpec] = React.useState(FormSpec);
  const [instance, setInstance] = React.useState({
    user_id: '',
    product: '',
  });
  const [cart, setCart] = React.useState(null);
  const [points, setPoints] = React.useState(null);
  const [tbaHoursRecords, setTbaHoursRecords] = React.useState(null);
  const [useFullPoints, setUseFullPoints] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const [isShowWarning, setIsShowWarning] = React.useState(false);

  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <div className="title-container">
        <h1 className="title">活動/課程快捷報名</h1>
      </div>

      <div className="content-container">
        <AdminLineDivider title="第一步：設定會員報名活動/課程" />
        <div>
          <GenericForm
            {...formSpec}
            instance={instance}
            rjsfProps={rjsfProps}
            onChange={async (formData) => {
              if (formData.product) {
                AppActions.setLoading(true);

                try {
                  const targetProduct = await JStorage.fetchOneDocument(
                    'product',
                    {
                      id: formData.product,
                    },
                  );

                  const hasVariants =
                    Array.isArray(targetProduct.variants) &&
                    targetProduct.variants.length > 0;

                  const nextValues = {
                    ...FormSpec,
                    schema: {
                      title: '',
                      type: 'object',
                      required: ['user_id', 'product'],
                      properties: {
                        user_id: {
                          type: 'string',
                          title: '會員',
                        },
                        product: {
                          type: 'string',
                          title: '欲報名活動/課程',
                        },
                      },
                    },
                  };

                  if (hasVariants) {
                    targetProduct.variants.map((e, index) => {
                      return (nextValues.schema.properties[e.name] = {
                        type: 'string',
                        title: `加價選項 ${e.name}`,
                        default: e.choices.map((c) => c.name)[0],
                        enum: e.choices.map((c) => c.name),
                        enumNames: e.choices.map(
                          (c) =>
                            `[${c.label}] 費用：$${c.price}元 / 人數：${c.tba_seat}人`,
                        ),
                      });
                    });
                  }

                  setFormSpec(nextValues);

                  if (
                    targetProduct.stock_type !== 'always' &&
                    !isNaN(targetProduct.stock_amount) &&
                    !isNaN(targetProduct.stock_sold_amount) &&
                    targetProduct.stock_amount <=
                      targetProduct.stock_sold_amount
                  ) {
                    setIsShowWarning(true);
                  }
                } catch (err) {
                  console.warn(err);
                  message.error('發生錯誤');
                  setFormSpec(FormSpec);
                } finally {
                  AppActions.setLoading(false);
                }
              }
            }}
            onSubmit={async (formData) => {
              const {user_id, product, points, ...variants} = formData;

              AppActions.setLoading(true);

              try {
                //fetch cart
                await AppActionsEx.adminFetchCartByUser({
                  user_id: formData.user_id,
                  initial_clear: true,
                  initial_raise: true,
                });

                //add to cart
                const cart = await AppActionsEx.adminAddToCartByUser({
                  product,
                  user_id,
                  config: {
                    qty: 1,
                    variants: Object.keys(variants).map((k) => ({
                      name: k,
                      choice: variants[k],
                    })),
                    extra_data: {},
                  },
                });
                setCart(cart);

                //fetch user points
                const upr = await JStorage.fetchOneDocument(
                  'private_profile',
                  {
                    owner: formData.user_id,
                  },
                  {owner: 1, name: 1, points: 1},
                );
                setPoints(upr.points);

                //fetch user tba hours
                const studyHours = await JStorage.fetchDocuments(
                  'study_hours',
                  {
                    user_id: formData.user_id,
                    first_year: {
                      $in: [
                        new Date().getFullYear() - 1911,
                        new Date().getFullYear() - 1912,
                      ],
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 2},
                );
                setTbaHoursRecords(studyHours.results);
              } catch (err) {
                console.warn(err);

                if (err.response) {
                  message.error(`發生錯誤：${err.response.error}`);
                } else {
                  message.error('發生錯誤');
                }
              } finally {
                AppActions.setLoading(false);
              }
            }}
          />
        </div>

        <AdminLineDivider title="第二步：設定會員優惠" />
        <div>
          {(points || tbaHoursRecords) && (
            <Descriptions bordered title="會員時數" style={{marginBottom: 20}}>
              <Descriptions.Item label="姓名" span={3}>
                {cart.buyer_name}
              </Descriptions.Item>
              <Descriptions.Item label="今年度免費時數">
                {points || 0} 小時
              </Descriptions.Item>
              {tbaHoursRecords?.map((r) => {
                const isLastYear =
                  r.first_year === new Date().getFullYear() - 1912;
                const label = `${isLastYear ? '去年度' : '今年度'}進修時數`;

                return (
                  <Descriptions.Item label={label}>
                    {r.hours} 小時
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
          {cart && (
            <div>
              <Descriptions
                bordered
                title="價格計算"
                style={{marginBottom: 20}}>
                {cart.discount_items.map((item) => {
                  return (
                    <Descriptions.Item label={item.name} span={3}>
                      -${item.amount}
                    </Descriptions.Item>
                  );
                })}
                {cart.extra_items.map((item) => {
                  return (
                    <Descriptions.Item label={item.name} span={3}>
                      {item.amount > 0
                        ? `+$${Math.abs(item.amount)}`
                        : `-$${Math.abs(item.amount)}`}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>

              <div class="custom-field">
                <div className="title">免費時數折抵</div>
                <div className="toggle">
                  <Switch
                    checked={useFullPoints}
                    disabled={points === 0}
                    onChange={async (checked) => {
                      if (cart.items[0].product.disable_discount) {
                        message.warning('不適用免費時數優惠');
                        return;
                      } else {
                        setUseFullPoints(checked);
                        setCart(
                          await AppActionsEx.adminOnUserDiscountByUser({
                            use_full_points: checked,
                            user_id: cart.owner,
                          }),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <AdminLineDivider title="第三步：確認報名資訊" />
        <div>
          {isShowWarning && (
            <Alert
              type="warning"
              showIcon
              message={`請注意：本課程／活動已額滿，您仍可繼續報名。`}
            />
          )}

          {cart && (
            <div>
              <div className="custom-field">
                <div className="title">
                  付款方式：
                  {Cart.PAYMENT_SUBTYPE_DISPLAY[cart.payment_subtype]?.label}
                </div>
              </div>
              <div className="custom-field">
                <div className="total">總金額：${cart.total}</div>
              </div>

              <div className="actions">
                <Button
                  style={{marginRight: 10}}
                  onClick={() => {
                    setFormSpec(FormSpec);
                    setInstance({user_id: '', product: ''});
                    setCart(null);
                    setPoints(null);
                    setTbaHoursRecords(null);
                    setUseFullPoints(false);
                    setRedirectUrl(null);
                  }}>
                  清空報名資料
                </Button>

                <Button
                  type="primary"
                  onClick={async () => {
                    AppActions.setLoading(true);
                    try {
                      const resp = await AppActionsEx.adminCheckoutbyUser({
                        user_id: cart.owner,
                        use_full_points: useFullPoints,
                      });

                      message.success('報名成功');
                      setRedirectUrl(resp.url);
                    } catch (err) {
                      console.warn(err);
                      message.error('發生錯誤');
                    } finally {
                      AppActions.setLoading(false);
                    }
                  }}>
                  確認結帳
                </Button>
                {redirectUrl && (
                  <Button
                    type="link"
                    target="_blank"
                    href={`${redirectUrl}&backPath=/admin/orders/export`}>
                    查看訂單
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['user_id', 'product'],
    properties: {
      user_id: {
        type: 'string',
        title: '會員',
      },
      product: {
        type: 'string',
        title: '欲報名活動/課程',
      },
    },
  },
  uiSchema: {
    user_id: {
      'ui:widget': 'admin-select-user-custom-widget',
    },
    product: {
      'ui:widget': 'admin-select-product-widget',
    },
  },
};

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 20px;

  & > .title-container {
    & > .title {
      margin-bottom: 10px;
      font-size: 32px;
    }
  }

  & > .content-container {
    margin-bottom: 10px;

    & .info {
      margin-bottom: 20px;
    }

    & .custom-field {
      margin-bottom: 20px;
      display: flex;

      & > .title {
        margin-right: 20px;
        font-size: 16px;
        font-weight: bold;
      }

      & > .total {
        font-size: 24px;
        font-weight: bold;
        color: var(--secondColor);
      }
    }

    & .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
